<script setup lang="ts">
import { AsfPromotionProps } from '@ui/types'

defineProps<AsfPromotionProps>()
const emit = defineEmits(['show:promo'])
const showPromoDetails = (payload: AsfPromotionProps) => emit('show:promo', payload)
</script>
<template>
  <div class="asf-promotion">
    <div class="asf-promotion__message">
      <slot>{{ discountMsg }}</slot>
    </div>

    <button v-if="details" class="asf-link asf-link-primary asf-promotion__details" @click="showPromoDetails($props)">
      {{ $t('product.promotionDetailBtn') }}
    </button>
  </div>
</template>
<style lang="postcss">
@import '@components/atoms/Promotion/Promotion.css';
</style>
